/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
html{background-color: #f4f6f9;}
/*  eye-icon css */

span.field-icon {position:absolute; display:inline-block; cursor:pointer; right:10px; top:10px; z-index:2;}

/*  eye-icon css end */

/* info icon css */

.info-icon {position:absolute; top:8px; right:20px; cursor:pointer;}

/* info icon css end */

/* tooltip css */

.tool-tip {border-bottom:none;}

.tooltiptext {
  visibility: hidden;
  width: 330px;
  background-color: #666;
  color: #fff;
  text-align: center;
  border-radius: 12px;
  padding: 8%;
  position: absolute;
  top: 89%;
  right: -100%;
  z-index: 999;
}

#tooltiptext {position:absolute; top:89%; right:-121%; padding:8%;}
.tool-tip:hover .tooltiptext {visibility:visible;}

/* header text on every page */

.header h3 {color:#398bf7;}

/* image-section */

.autometrics-img {width:100%;}

/* Primary-btn section */

#primary-btn {width:auto;}
.primary-btn {
  background-color: #398bf7;
  color: white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 130px;
}
.primary-btn:hover {color:#fff; background-color:#1475f6; border-color:#0a6ff3;}
.primary-btn:focus, .primary-btn.focus {
  color: #fff;
  background-color: #1475f6;
  border-color: #0a6ff3;
  box-shadow: 0 0 0 0.2rem rgba(87, 156, 248, 0.5);
}
.primary-btn.disabled, .primary-btn:disabled {
  color: #fff;
  opacity: 0.65;
  background-color: #398bf7;
  border-color: #398bf7;
}
.primary-btn:not(:disabled):not(.disabled):active,
.primary-btn:not(:disabled):not(.disabled).active,
.show>.primary-btn.dropdown-toggle {color:#fff; background-color:#0a6ff3; border-color:#0969e7;}

.primary-btn:not(:disabled):not(.disabled):active:focus,
.primary-btn:not(:disabled):not(.disabled).active:focus,
.show>.primary-btn.dropdown-toggle:focus {box-shadow:0 0 0 0.2rem rgba(87, 156, 248, 0.5);}

.btn-primary {background-color:#398bf7 !important; border-color:#398bf7 !important; border-radius:5px !important;}

.btn-primary:hover {box-shadow:none !important;}
.primary-btn-large {width:150px !important;}
/* Primary-btn css end */

/* btn-outline-primary */

.btn-outline-primary {
  width: 130px;
  border: 1px solid #298bf7 !important;
  color: #455a64 !important;
  font-weight: 400;
  border-radius: 5px !important;
}

.btn-outline-primary:hover {
  width: 130px;
  background-color: transparent !important;
  border: 1px solid #298bf7 !important;
  color: #455a64 !important;
  font-weight: 400;
  border-radius: 5px !important;
  box-shadow: none !important;
}

/* edit icon css */

.fa-edit {position:absolute; top:10px; right:10px;}
.bg-353c45 {background-color:#353c45;}
.fa-file-archive {color:#353c45;}

/* clone icon css */

.fa-clone {position:absolute; right:5px; top:5px; cursor:pointer;}

/* overflow css */

.overflow-x {overflow-x:scroll;}

/*  */

::placeholder {color:lightgray;}

/* refresh icon csc */

.mdi-refresh {
  height: 65px;
  width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: white;
}

.pointer {cursor:pointer;}

/* anchor */

.text-center a {cursor:pointer; color:#398bf7;}
.text-center a:hover {cursor:pointer; color:#1475f6 !important; transition:.3s ease-in-out;}

/* data table section */

.ngx-datatable.material .single-selection .datatable-body-row .active .datatable-row-group {background-color:#a4d2e7 !important; color: #ffffff !important;}
.ngx-datatable.material .datatable-header .datatable-header-cell {background-color:#2da7df !important; color:#fff !important;}
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {background-color:#a4d2e7 !important; color:#fff !important;}

  /* dicom viewer section ends */
  /* progress bar */
.progress {
  display: flex;
  height: 11px !important;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #ebf3f5;
  border-radius: 2px;
}
.progress-bar {background-color:#398bf7 !important;}
.popup {border-radius:20px; width:40%;}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06) !important;
}
/* chips css */
.disableKeyTermsInput, .input-tag {border:none; background:#ffffff !important;}
.keyTerms {background:#fff;}
.editor-tag {cursor:text; font-size:16px; border-radius:5px; background:white;}
.editor-tag .tag-item {
  border-radius: 30px;
  height: auto;
  font-size: 17px;
  display: inline-block;
  background: #398bf7;
  padding-left: 10px;
  color: white;
}
.editor-tag .tag-item .remove-tag {padding-right:5px; cursor:pointer;}
.editor-tag .tag-item:not(:first-child) {margin-left:5px;}
textarea,.head-inform, .disableKeyTerms, .editor-tag {border: 2px solid #00cecb !important;}
.disableKeyTerms {background:#f3f4f4 !important;}
.disableKeyTermsInput, .input-tag {border:none; background:#ffffff !important;}
/* chips css end */
/* csv and advance search css */
.matrices-text {font-size:20px; font-weight:500;}
.cross-icon {position:absolute; top:10px; right:13px; color:black; font-weight:600;}

.bg-1475f6 {background-color:#1475f6;}
.modal-body p {letter-spacing:-.4px;}
.checkbox-size {width:20px; height:20px;}
.clear-fix {clear:both;}
.searchForText {margin-left:17%; margin-top:2%;}
.upload-matrices-text {font-weight:500;}
.overflow-wrap {overflow-wrap:anywhere;}
.keyTermsContainer {height:100px !important;}
#keytermsInputField {border:none;}
.tag-item {border-radius:16px; margin-left:5px; padding:2px;}
.bg-398bf7 {background-color:#398bf7;}
.upload-file-btn {padding:7px 36px; background-color:#398bf7; border-radius:5px;}
#CSVFileDropRef {display:none;}
.nav-tabs .nav-link.active {background-color:#2da7df !important; color:#fff !important; cursor:pointer;}
.nav-tabs .nav-link {cursor:pointer;}

/* csv and advance search css end */

/* dicom upload css */
.default-size{
  background-color: #398bf7;
  color: white;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 130px;
  height: 38px;
}
#fileDropRef {
  opacity: 0;
  height: 30px;
  width: 100%;
  position: relative;
  left: 5px;
  top: -40px;
}
.margin {margin-top:.4rem;}
.border-left {margin:2% 2% 0% 2%;}
/* dicom upload css end */

/* upload component css */
.files-list {
  margin-top: 1.5rem;
}
.files-list .single-file img.delete {
  margin-left: 0.5rem;
  cursor: pointer;
  align-self: flex-end;
}
.files-list .single-file .name {
  font-size: 14px;
  font-weight: 500;
  color: #353f4a;
  margin: 0;
}
.files-list .single-file .size {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4a4;
  margin: 0;
  margin-bottom: 0.25rem;
}
.files-list .single-file .info {width:100%}
.files-list .single-file {
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  border: dashed 1px #979797;
  margin-bottom: 1rem;
  display: flex;
  flex-grow: 1;
}
.upload-div-text {font-weight:600; color:rgb(29, 100, 16);}
.shadow          {box-shadow:0 .5rem 1rem rgba(0,0,0,.08)!important;}

/* upload component css end */

/* Download Modal shared component overlay */
.overlay-fade {
  opacity:    0.5;
  background: #000;
  width:      100%;
  height:     100%;
  z-index:    999;
  top:        0;
  left:       0;
  position:   fixed;
}

#FileTagText ,#text{
  width: 10px;
    overflow: hidden;
    text-indent: -9px;
    height: 5px;
    position: absolute;
    z-index: -9999;
}

/* user management css */
/* login  */
.login-box {
  padding-top: 10px;
  width: 400px;
  margin: 0 auto;
  height: 70vh;
}
.form-container-inner{
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  margin: 0 auto;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 10px 6px -6px #777;
  width: 400px;
}
.form-title {color:#1e88e5; font-size:36px; margin:20px 0px; line-height:40px;}
.login-box .form-group {margin-top:2rem;}
.forgot-password-link  {font-size:11px; margin-right:10px; float:right; color:#E63C72;}

@media (max-width:999px) {
  .datatable-body {overflow-x:auto !important;}
}

/* alert */
.alert-messages {
  position: fixed !important;
  top: 5px;
  z-index: 9999;
  left: 40%;
  min-width: 20%;
}
/* spinner */
.spinner-grow {width:20px !important; height:20px !important;}
/* font color cssd */

.text-67757c {color:#67757c !important;}

/* font color css end */

.w-15 {width: 15% !important; min-width: 115px !important;}

/* user management css */

.navbar-brand {font-size:20px; font-weight:500; color:#009efb !important;}
.navbar-expand-lg.navbar-light {
    /* background-color: #fff; */
    /* margin:-20px -20px 30px -20px !important; */
    /* box-shadow:5px 0px 10px rgba(0, 0, 0, 0.1) ; */
}
.navbar-light .navbar-nav .nav-link {
    color:#1e88e5 !important;
    font-weight: 400;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 16px;
}
.navbar-light .navbar-nav .nav-link:hover {text-decoration: underline; cursor: pointer;}

.custome-drag {
    position: relative;
    background-color: #f6f6f6;
    border-radius: 2px;
    transition: border-color .3s,background-color .3s;
    margin: 20px 0px;
    width: 100%;
    height: 200px;

}
label {color:#111;}
.custome-drag > input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.custome-drag > label {
    position: absolute;
    left: 0;
    right: 0;
    top:50%;
    text-align: center;
    font-size:14px;
    line-height: 18px;
    margin:0;
    margin-top: -9px;
    font-weight: 600;
    color: black;
}
.btn-rounded {border-radius:4px !important;}

.scroller-table .datatable-body {
    max-height:calc(70vh - 220px) !important;
    overflow-y:auto !important;
    overflow-x:hidden !important;
}
.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color:#b8daff !important;
    color:transparent !important;
}
.datatable-header-cell {
    text-align: left;
    color: #000 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    background-color: #f5f5f5 !important;
    padding: 0.9rem 1rem !important;
}
.datatable-body-cell {
    border-bottom:#f3f1f1 1px solid !important;
    font-size: 12px !important;
    padding: 0.6rem 1rem !important;
}
angular2csv > button {
    cursor: pointer;
    background: #398bf7 !important;
    color: #398bf7 !important;
    padding: .375rem .25rem !important;
    border: none !important;
    width: 50px;
    border-radius: 4px !important;
    float: left;
    margin-right: 10px;
}
angular2csv > button {
    color: transparent;  /* hides 'download' label */
    line-height: 0;
    height: 37px;     /* Collapse the original line */
}

angular2csv > button::after {
    content: "CSV";        /* new button label */
    color: #FFF;         /* color so we can see it */
    display: block;
    line-height: initial;  /* New content takes up original line height */
}
/* user management css end */

/* tenants css */
.nav-pills {border-bottom:#EEE 1px solid !important;}
.nav-pills .nav-link {
    padding: 5px 12px !important;
    font-size: 14px !important;
    margin-left: 1rem;
    transition: .4s ease-in-out;
    border-radius: 0 !important;
}
.nav-pills .nav-link:hover {background-color:#398bf7 !important; color:#fff !important;}
/* user management css end */

.build_no {font-size:14px; color:#263238; font-weight: 500; position:fixed; bottom:10px; right:15px;}
.serial_no {font-size:14px; color:#263238; font-weight: 500; position:fixed; bottom:30px; right:15px;}
.platform_no {font-size:14px; color:#263238; font-weight: 500; position:fixed; bottom:50px; right:15px;}
.version_no.top {font-size:14px; color:#FFF; position: fixed; top:25px; right:135px;}

/* about us pagination */
.pagination {justify-content: end;}

/*--- Verticle Side-bar-About Menu at bottom ---*/

.sidebar-nav {
  position: relative;
  height: 100%;
  top: 0;
  bottom: 0;
  width:100%;
}
.sidebar-nav > ul {
  position: relative;
  height: 100%;
  top: 0;
  bottom: 0;
  width:100%;
}
.sidebar-nav > ul > .sidebar-item:last-child {position:absolute; bottom:0;}
