// Sidebar
$sidebar-link-padding: 12px 15px;
$sidebar-icon-size: 20px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 8px 15px;
$sidebar-first-level-icon-size: 14px;
$sidebar-second-level-padding: 0 0 0px 20px;
$small-cap-padding: 12px 23px;

/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;
  z-index: 99;
  padding-top: $topbar-height;
  background: $sidebar;
  box-shadow: $shadow;
  transition: 0.2s ease-in;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 20px);
  // overflow: auto;
  position: relative;
}

.ps__rail-y {
  z-index: 9;
}

.sidebar-nav {
  ul {
    .sidebar-item {
      // width: $sidebar-width-full;
      margin-bottom: 5px;
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        opacity: 0.6;
        cursor: pointer;
        font-size: 18px;
        i-feather {
          margin: 0 8px 2px 5px;
        }
        .feather {
          height: 18px;
          width: 18px;
          margin: 0 8px 2px 5px;
          fill: rgba(255, 255, 255, 0.2);
          color: $sidebar-icons;
        }
        .side-badge.badge {
          position: absolute;
          right: 32px;
          top: 17px;
          padding: 3px 10px;
          border-radius: 4px;
        }
        i {
          font-style: normal;
          width: 35px;
          line-height: 25px;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
        }
        
        &:hover {
          opacity: 1;
          .feather {
            color: $white;
          }
        }
      }
      
      &.selected > .sidebar-link,
      &:hover > .sidebar-link {
        /*background: rgba(0, 0, 0, 0.04);*/
        opacity: 1;
      }
      /*******************
					 First level
					 ******************/
      .first-level {
        padding: $sidebar-first-level-padding;
        .sidebar-item.active > .sidebar-link {
          opacity: 1;
        }
        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
            visibility: hidden;
          }
        }
      }
      .second-level .sidebar-item {
        padding: $sidebar-second-level-padding;
      }
      &.active {
        > .sidebar-link {
          opacity: 1;
        }
      }
    }
    /*******************
		 Small cap
		 ******************/
    .nav-small-cap {
      font-size: 12px;
      padding: $small-cap-padding;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-text;
      text-transform: uppercase;
      opacity: 0.4;
      i {
        line-height: 30px;
        margin: 0 5px;
        display: none;
      }
    }
  }
}
/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
}
.collapse.in {
  display: block;
}
/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(-135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 26px;
    right: 15px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

.user-profile {
  padding: 10px 0 10px 10px;
  border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  > .sidebar-link {
    padding: 12px 0 8px 8px !important;
  }
  span {
    img {
      width: 30px;
      border-radius: 100%;
      margin-right: 10px;
    }
  }
  ul {
    padding-left: 47px;
    li {
      margin-top: 8px;
    }
  }
}

// truncate
.hide-menu {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 35px;
}

@media screen and (width: 1024px) {
  .sidebar-nav ul .sidebar-item .sidebar-link {
    padding: 12px 9px;
  }
}
